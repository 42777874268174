<template>
  <v-container>
    <v-card>
        <v-card-title>This is about us page</v-card-title>
    </v-card>
  </v-container>
</template>

<script>
export default {

}
</script>